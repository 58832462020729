import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4-4-4-4 to a 4RM`}</p>
    <p>{`Straight Arm Pulldowns 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`Three 2:00 Rounds of:`}</p>
    <p>{`15/12 Calorie Row (RX+=20/15 Calorie)`}</p>
    <p>{`Max Reps S2OH (95/65)`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`Three 2:00 Rounds of:`}</p>
    <p>{`15 Box Jump Overs, 24/20″ (RX+=20 reps)`}</p>
    <p>{`Max Reps KBS’s (53/35`}{`#`}{`)`}</p>
    <p>{`Score = Total Reps of S2OH & KBS`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`5-Clapping Pushups`}</p>
    <p>{`10-V Ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      